import { Box, Button, Grid, useTheme } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import _ from "lodash";
import moment from "moment";
import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useAuth } from "../../providers/AuthProvider";
import { currency } from "../../utils/utility";
import "./MiniBillFormat.css";
var converter = require("number-to-words");

MiniBillTaxableFormat.defaultProps = {
  data: {
    items: [],
  },
};

function MiniBillTaxableFormat({ data, onBack, updateStatus }) {
  const theme = useTheme();
  const { userData } = useAuth();
  const location = useLocation();

  const {
    userData: { businessDetails },
  } = useSelector((state) => state.general);

  function printdiv(elem) {
    var header_str =
      "<html><head><title>" + document.title + "</title></head><body>";
    var footer_str = "</body></html>";
    var new_str = document.getElementById(elem).innerHTML;
    var old_str = document.body.innerHTML;
    document.body.innerHTML = header_str + new_str + footer_str;
    window.print();
    document.body.innerHTML = old_str;
    // return false;
  }
  //   let {
  //     state: { data },
  //   } = location;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid item xs={12}>
          <Box display={"flex"}>
            <Box flex={1}>
              <Button
                onClick={() => {
                  onBack();
                }}
              >
                <ArrowBackIosIcon /> Back
              </Button>
            </Box>
            <Box>
              <Button
                onClick={updateStatus}
                color="primary"
                variant="contained"
              >
                Update Status
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Box ref={componentRef} id="printable_div_id">
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ backgroundColor: "white", padding: "10px" }}>
                  <table
                    style={{
                      width: "80mm",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tr>
                      <th colSpan={2}>{_.upperCase(businessDetails.name)}</th>
                    </tr>
                    <tr>
                      <th style={{ fontSize: "12px" }} colSpan={2}>
                        Address: {businessDetails.address}
                        <div>
                          {businessDetails.city_name +
                            ", " +
                            businessDetails.state_name +
                            " (" +
                            businessDetails.pincode +
                            ")"}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ fontSize: "12px" }} colSpan={2}>
                        {/* GSTIN: 24BCYPR2433R1ZN
                      <br />
                      FSSAI: 10721021000240
                      <br /> */}
                        Email: {businessDetails.email}
                        <br />
                        Mobile: {businessDetails.contact_number}
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="pos_table_content"
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <hr />
                        Name: {data?.name}
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="pos_table_content"
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <hr />
                        Phone Number: {data?.mobile}
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="pos_table_content"
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <hr />
                        Order ID: <br />{" "}
                        <span style={{ fontSize: "13px" }}>{data.cart_id}</span>
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="pos_table_content"
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <hr />
                        Date: {moment(data.created_at).format("DD/MM/YYYY")}
                      </th>
                    </tr>
                    <tr className="pos_table_content">
                      <td colSpan={2} style={{ textAlign: "left" }}>
                        <hr />
                        <table style={{ width: "100%" }}>
                          <tr>
                            <th>No.</th>
                            <th>Product.</th>
                            <th>Qty.</th>
                            <th>Tax</th>
                            <th>Rate</th>
                            <th>Discount</th>
                            <th>Amount</th>
                          </tr>
                          <tr>
                            <td colSpan={7}>
                              <hr />
                            </td>
                          </tr>
                          {data.items.map((item, index) => (
                            <>
                              <tr>
                                <td colSpan={4}>
                                  {item.name + " "}
                                  {Object.keys(item.options).length > 0 &&
                                    `[${Object.keys(item.options)
                                      .map((key) => {
                                        return `${key}: ${item.options[key].value}`;
                                      })
                                      .join(", ")}]`}
                                </td>
                              </tr>
                              <tr>
                                <td width="70px">{index + 1}</td>
                                <td width="70px"></td>
                                <td width="70px">{item.quantity}</td>
                                <td width="70px">{item.tax}%</td>
                                <td width="70px">{item.product_price}</td>
                                <td width="70px">{item.discount}%</td>
                                <td width="70px">
                                  {item.total_price.toFixed(2)}
                                </td>
                              </tr>
                            </>
                          ))}
                        </table>
                      </td>
                    </tr>
                    <tr className="pos_table_content">
                      <td>
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div></div>
                          <div>
                            <b>CGST:</b>{" "}
                            {currency({
                              value: data?.total_taxable_amount / 2,
                              currency: userData.currency,
                            })}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="pos_table_content">
                      <td>
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div></div>
                          <div>
                            <b>SGST:</b>{" "}
                            {currency({
                              value: data?.total_taxable_amount / 2,
                              currency: userData.currency,
                            })}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="pos_table_content">
                      <td>
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <b>Total Quantity:</b>{" "}
                            {data.items.reduce((acc, item) => {
                              acc += item.quantity;
                              return acc;
                            }, 0)}
                          </div>
                          <div>
                            <b>Total Amount:</b>{" "}
                            {currency({
                              value: data?.total_payable_with_tax,
                              currency: userData.currency,
                            })}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="pos_table_content">
                      <td
                        colSpan={2}
                        style={{
                          textAlign: "center",
                          padding: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Thanks For Visiting {_.capitalize(businessDetails.name)}
                        <div>
                          {data.cart_id && (
                            <QRCode
                              style={{ marginTop: "10px", height: "30px" }}
                              value={data.cart_id}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </Box>
            </Box>
          </Box>
          <Box textAlign={"end"} marginRight={"10px"}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                handlePrint();

                // var element = document.getElementById("printable_div_id");
                // html2pdf(element);

                // printdiv("printable_div_id");
                // window.print();
              }}
            >
              Print
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default MiniBillTaxableFormat;
